import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Features from "../components/features"
import FullCTA from "../components/fullCTA"
import { BodyText } from "../components/bodyText"
import OverlayText from "../components/overlayText"
import { Sort } from "../services/utils"
import { useAnchor } from "../effects/anchor.effect"

const HowItWorks = ({ data }) => {
  const article = data.tst.Page || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  article.pageMetaData.url = article.url
  const ctas = Sort(article.ctas, article.ctasSort)
  const brandsCTA = ctas[0]
  const bottomCTAS = ctas.slice(1, ctas.length)

  useAnchor()

  const steps = article.features.slice(0, 5)
  const theRest = article.features[5]
  return (
    <Layout meta={article.pageMetaData || { title: article.title }}>
      <Hero
        data={article.hero}
        tagline="Personal Styling Simplified"
        className="fixed"
        type="text"
      />
      <OverlayText>
        <BodyText article={article} />
        <Features features={steps} startSide="right" />
        <FullCTA data={brandsCTA} />
        <Features features={[theRest]} />
        {bottomCTAS &&
          bottomCTAS.length > 0 &&
          bottomCTAS.map(cta => <FullCTA data={cta} />)}
      </OverlayText>
    </Layout>
  )
}

export default HowItWorks

export const query = graphql`
  query HowItWorksQuery($id: ID!) {
    tst {
      Page(where: { id: $id }) {
        name
        pageTitle
        text
        url
        ctas {
          ...CTA
        }
        ctasSort
        features {
          ...Feature
        }
        hero {
          ...Hero
        }
        pageMetaData {
          ...Meta
        }
      }
    }
  }
`
